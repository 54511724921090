<template>
  <div style="color: #666;font-size: 14px;">
    <el-collapse v-model="activeNames" @change="handleChange">
      <el-collapse-item title="车间工单统计数据" name="1">
        <el-row :gutter="10" style="margin-bottom: 60px">
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> 电镀一线</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{yixianSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{yixianHour}}h
              </div>
            </el-card>
          </el-col>
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> 电镀二线</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{erxianSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{erxianHour}}h
              </div>
            </el-card>
          </el-col>
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> 电镀三线</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{sanxianSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{sanxianHour}}h
              </div>
            </el-card>
          </el-col>
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> 铸造</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{zhuzaoSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{zhuzaoHour}}h
              </div>
            </el-card>
          </el-col>
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> 压铸</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{yazhuSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{yazhuHour}}h
              </div>
            </el-card>
          </el-col>
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> 机加</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{jijiaSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{jijiaHour}}h
              </div>
            </el-card>
          </el-col>
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> 工模</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{gongmoSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{gongmoHour}}h
              </div>
            </el-card>
          </el-col>
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> 研磨</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{yanmoSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{yanmoHour}}h
              </div>
            </el-card>
          </el-col>
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> 涂装</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{tuzhuangSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{tuzhuangHour}}h
              </div>
            </el-card>
          </el-col>
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> QA品保</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{QApinbaoSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{QApinbaoHour}}h
              </div>
            </el-card>
          </el-col>
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> 生活污水</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{shenghuoSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{shenghuoHour}}h
              </div>
            </el-card>
          </el-col>
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> PVD</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{pvdSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{pvdHour}}h
              </div>
            </el-card>
          </el-col>
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> 注塑</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{zhusuSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{zhusuHour}}h
              </div>
            </el-card>
          </el-col>
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> 设施维修</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{sheshiSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{sheshiHour}}h
              </div>
            </el-card>
          </el-col>
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> 立库</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{likuSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{likuHour}}h
              </div>
            </el-card>
          </el-col>
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> 组装</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{zuzhuangSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{zuzhuangHour}}h
              </div>
            </el-card>
          </el-col>
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> 花洒组装</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{huasaSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{huasaHour}}h
              </div>
            </el-card>
          </el-col>
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> QA组装</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{QAzuzhuangSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{QAzuzhuangHour}}h
              </div>
            </el-card>
          </el-col>
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> 行政楼</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{xingzhengSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{xingzhengHour}}h
              </div>
            </el-card>
          </el-col>
          <el-col :span="3">
            <el-card style="color: #409EFF">
              <div><i class="el-icon-s-order" /> 污水处理</div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工单数:{{wushuiSum}}
              </div>
              <div style="padding: 10px 0; text-align: center; font-weight: bold; color: #000000">
                工时:{{wushuiHour}}h
              </div>
            </el-card>
          </el-col>
        </el-row>
        <div class="chart-container">
          <div ref="chart" class="chart"></div>
          <div ref="chart2" class="chart"></div>
        </div>
      </el-collapse-item>

      <el-collapse-item title="车间数据" name="2">
        <el-card v-for="item in lines" :key="item.id" style="margin-bottom: 20px;">
          <div >
            <span style="font-weight: bold;font-size: 20px">{{ item.name }}  |</span>
            <span style="margin-left: 50px;font-size: 18px">总工单数:{{ item.recordnum }}</span>
            <span style="margin-left: 50px;font-size: 18px">总工时:{{ item.hour }}h</span>
          </div>

          <el-divider></el-divider>
          <div style="font-size: 18px">
            <span style="margin-left: 50px">车间报修工单数:{{ item.hour }}</span>
            <span style="margin-left: 50px">车间报修工时:{{ item.hour }}h</span>
            <span style="margin-left: 50px">维修自查工单数:{{ item.hour }}</span>
            <span style="margin-left: 50px">维修自查工时:{{ item.hour }}h</span>

          </div>

          <div style="font-size: 18px">
            <span style="margin-left: 50px">管理巡审工单数:{{ item.hour }}</span>
            <span style="margin-left: 50px">管理巡审工时:{{ item.hour }}h</span>
            <span style="margin-left: 50px">获批技改工单数:{{ item.hour }}</span>
            <span style="margin-left: 50px">获批技改工时:{{ item.hour }}h</span>
          </div>

        </el-card>
      </el-collapse-item>

    </el-collapse>

  </div>
</template>

<script>

import * as echarts from 'echarts/core';
import { PieChart } from 'echarts/charts';
import { TooltipComponent, LegendComponent, TitleComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([PieChart, TooltipComponent, LegendComponent, TitleComponent, CanvasRenderer]);

export default {
  name: "LineChart",
  data() {
    return {
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
      users: [],
      groups: [],
      morningShift: [],
      afternoonShift: [],
      eveningShift: [],
      maintainers: [],
      failureRecords: [],
      lines: [],
      usernum: 0,
      mtnum: 0,
      recordnum: 0,
      weekRecordnum: 0,
      EmergencyRecord: 0,
      PreventiveRecord: 0,
      reportsum: 0,
      activeNames: ['1'],
      yixianSum: 0,
      erxianSum: 0,
      sanxianSum: 0,
      zhuzaoSum: 0,
      yazhuSum: 0,
      jijiaSum: 0,
      gongmoSum: 0,
      yanmoSum: 0,
      tuzhuangSum: 0,
      QApinbaoSum: 0,
      shenghuoSum: 0,
      pvdSum: 0,
      zhusuSum: 0,
      sheshiSum: 0,
      likuSum: 0,
      zuzhuangSum: 0,
      huasaSum: 0,
      QAzuzhuangSum: 0,
      xingzhengSum: 0,
      wushuiSum: 0,

      yixianHour: 0,
      erxianHour: 0,
      sanxianHour: 0,
      zhuzaoHour: 0,
      yazhuHour: 0,
      jijiaHour: 0,
      gongmoHour: 0,
      yanmoHour: 0,
      tuzhuangHour: 0,
      QApinbaoHour: 0,
      shenghuoHour: 0,
      pvdHour: 0,
      zhusuHour: 0,
      sheshiHour: 0,
      likuHour: 0,
      zuzhuangHour: 0,
      huasaHour: 0,
      QAzuzhuangHour: 0,
      xingzhengHour: 0,
      wushuiHour: 0,
    }
  },
  created() {
    this.load()
  },
  mounted() {
    this.initChart();
  },
  methods: {
    load() {
      this.request.get("/failureRecord/getLineRecordSum").then(res => {
        this.yixianSum = res.data.yixianSum ? res.data.yixianSum : 0
        this.erxianSum = res.data.erxianSum ? res.data.erxianSum : 0
        this.sanxianSum = res.data.sanxianSum ? res.data.sanxianSum : 0
        this.zhuzaoSum = res.data.zhuzaoSum ? res.data.zhuzaoSum : 0
        this.yazhuSum = res.data.yazhuSum ? res.data.yazhuSum : 0
        this.jijiaSum = res.data.jijiaSum ? res.data.jijiaSum : 0
        this.gongmoSum = res.data.gongmoSum ? res.data.gongmoSum : 0
        this.yanmoSum = res.data.yanmoSum ? res.data.yanmoSum : 0
        this.tuzhuangSum = res.data.tuzhuangSum ? res.data.tuzhuangSum : 0
        this.QApinbaoSum = res.data.QApinbaoSum ? res.data.QApinbaoSum : 0
        this.shenghuoSum = res.data.shenghuoSum ? res.data.shenghuoSum : 0
        this.pvdSum = res.data.pvdSum ? res.data.pvdSum : 0
        this.zhusuSum = res.data.zhusuSum ? res.data.zhusuSum : 0
        this.sheshiSum = res.data.sheshiSum ? res.data.sheshiSum : 0
        this.likuSum = res.data.likuSum ? res.data.likuSum : 0
        this.zuzhuangSum = res.data.zuzhuangSum ? res.data.zuzhuangSum : 0
        this.huasaSum = res.data.huasaSum ? res.data.huasaSum : 0
        this.QAzuzhuangSum = res.data.QAzuzhuangSum ? res.data.QAzuzhuangSum : 0
        this.xingzhengSum = res.data.xingzhengSum ? res.data.xingzhengSum : 0
        this.wushuiSum = res.data.wushuiSum ? res.data.wushuiSum : 0

        this.yixianHour = res.data.yixianHour ? res.data.yixianHour : 0
        this.erxianHour = res.data.erxianHour ? res.data.erxianHour : 0
        this.sanxianHour = res.data.sanxianHour ? res.data.sanxianHour : 0
        this.zhuzaoHour = res.data.zhuzaoHour ? res.data.zhuzaoHour : 0
        this.yazhuHour = res.data.yazhuHour ? res.data.yazhuHour : 0
        this.jijiaHour = res.data.jijiaHour ? res.data.jijiaHour : 0
        this.gongmoHour = res.data.gongmoHour ? res.data.gongmoHour : 0
        this.yanmoHour = res.data.yanmoHour ? res.data.yanmoHour : 0
        this.tuzhuangHour = res.data.tuzhuangHour ? res.data.tuzhuangHour : 0
        this.QApinbaoHour = res.data.QApinbaoHour ? res.data.QApinbaoHour : 0
        this.shenghuoHour = res.data.shenghuoHour ? res.data.shenghuoHour : 0
        this.pvdHour = res.data.pvdHour ? res.data.pvdHour : 0
        this.zhusuHour = res.data.zhusuHour ? res.data.zhusuHour : 0
        this.sheshiHour = res.data.sheshiHour ? res.data.sheshiHour : 0
        this.likuHour = res.data.likuHour ? res.data.likuHour : 0
        this.zuzhuangHour = res.data.zuzhuangHour ? res.data.zuzhuangHour : 0
        this.huasaHour = res.data.huasaHour ? res.data.huasaHour : 0
        this.QAzuzhuangHour = res.data.QAzuzhuangHour ? res.data.QAzuzhuangHour : 0
        this.xingzhengHour = res.data.xingzhengHour ? res.data.xingzhengHour : 0
        this.wushuiHour = res.data.wushuiHour ? res.data.wushuiHour : 0
        this.initChart();
        this.initChart2();
      })

      this.request.get("/line").then(res => {
        this.lines = res.data;
      })

    },
    initChart() {
      const chartDom = this.$refs.chart;
      const myChart = echarts.init(chartDom);

      const option = {
        title: {
          text: '车间工单饼图',
          left: 'center',
        },
        tooltip: {
          trigger: 'item',
        },
        legend: {
          top: 'right',
        },
        series: [
          {
            name: '分类',
            type: 'pie',
            radius: '50%',
            data: [
              { value: this.yixianSum, name: '电镀一线' },
              { value: this.erxianSum, name: '电镀二线' },
              { value: this.sanxianSum, name: '电镀三线' },
              { value: this.zhuzaoSum, name: '铸造' },
              { value: this.yazhuSum, name: '压铸' },
              { value: this.jijiaSum, name: '机加' },
              { value: this.gongmoSum, name: '工模' },
              { value: this.yanmoSum, name: '研磨' },
              { value: this.tuzhuangSum, name: '涂装' },
              { value: this.QApinbaoSum, name: 'QA品保' },
              { value: this.shenghuoSum, name: '生活污水' },
              { value: this.pvdSum, name: 'PVD' },
              { value: this.zhusuSum, name: '注塑' },
              { value: this.sheshiSum, name: '设施维修' },
              { value: this.likuSum, name: '立库' },
              { value: this.zuzhuangSum, name: '组装' },
              { value: this.huasaSum, name: '花洒组装' },
              { value: this.QAzuzhuangSum, name: 'QA组装' },
              { value: this.xingzhengSum, name: '行政楼' },
              { value: this.wushuiSum, name: '污水处理' },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      };

      myChart.setOption(option);
    },
    initChart2() {
      const chartDom = this.$refs.chart2;
      const myChart = echarts.init(chartDom);

      const option = {
        title: {
          text: '车间工时饼图',
          left: 'center',
        },
        tooltip: {
          trigger: 'item',
        },
        legend: {
          top: 'right',
        },
        series: [
          {
            name: '分类',
            type: 'pie',
            radius: '50%',
            data: [
              { value: this.yixianHour, name: '电镀一线' },
              { value: this.erxianHour, name: '电镀二线' },
              { value: this.sanxianHour, name: '电镀三线' },
              { value: this.zhuzaoHour, name: '铸造' },
              { value: this.yazhuHour, name: '压铸' },
              { value: this.jijiaHour, name: '机加' },
              { value: this.gongmoHour, name: '工模' },
              { value: this.yanmoHour, name: '研磨' },
              { value: this.tuzhuangHour, name: '涂装' },
              { value: this.QApinbaoHour, name: 'QA品保' },
              { value: this.shenghuoHour, name: '生活污水' },
              { value: this.pvdHour, name: 'PVD' },
              { value: this.zhusuHour, name: '注塑' },
              { value: this.sheshiHour, name: '设施维修' },
              { value: this.likuHour, name: '立库' },
              { value: this.zuzhuangHour, name: '组装' },
              { value: this.huasaHour, name: '花洒组装' },
              { value: this.QAzuzhuangHour, name: 'QA组装' },
              { value: this.xingzhengHour, name: '行政楼' },
              { value: this.wushuiHour, name: '污水处理' },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      };

      myChart.setOption(option);
    },
    handleChange(val) {
      console.log(val);
    },
  }
}
</script>
<style scoped>
.tree-chart-container {
  width: 100%;
  height: 1000px; /* 确保有足够的高度 */
}
.chart-container {
  display: flex; /* 使用 Flexbox 布局 */
  justify-content: space-between; /* 控制间距 */
}

.chart {
  width: 500px; /* 每个图表的宽度 */
  height: 500px; /* 每个图表的高度 */
}
</style>
